<template>
        <el-dialog :title="mode=='add' ? 'Adaugare client nou' : 'Editare client' " :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal="false">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item label='Nume' >
                    <el-input  class='full-width' v-model='selectedObject.Nume' />
                </el-form-item>
            </el-col>
            
            <el-col :md='8'>
                <el-form-item label='Email' >
                    <el-input  class='full-width' v-model='selectedObject.Email' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Email intern' >
                    <el-input  class='full-width' readonly disabled v-model='selectedObject.EmailIntern' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Telefon' >
                    <el-input  class='full-width' v-model='selectedObject.Telefon' />
                </el-form-item>
            </el-col>            

            <el-col :md='24'>
                <el-form-item label='Observatii' >
                    <el-input  class='full-width' v-model='selectedObject.Observatii' type='textarea' />
                </el-form-item>
            </el-col>
            
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Clienti_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    Nume: '' , Observatii: '' , Email: '' , Telefon: '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Observatii: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Email: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Telefon: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("clienti/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info() {
                var response = await this.post("clienti/get_info_for_dialog" ); 
            },

            save: async function() {
                
                var confirm =  await this.$confirm(
                    this.mode == "add" ? 
                        `Sunteti sigur ca doriti sa adaugam un client nou pe nume '${this.selectedObject.Nume}'?` :
                        `Sunteti sigur ca doriti sa salvam modificarile la clientul '${this.selectedObject.Nume}'?` 
                , 'Warning');
                if( confirm )
                {
                    this.$refs['my-form'].validate( async(valid) => {
                        if (valid)
                        {
                            var response = await this.post("clienti/save", { mode: this.mode, object: this.selectedObject } );
                            this.showPopup = false;
                            this.$emit("save", { mode: this.mode, new_id: response.new_id, new_name: response.new_name });
                        }
                    });
                }
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>