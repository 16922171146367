<template>
    <base-page>
    <el-card  class="filtre-albastre no-padding-card" >
            <el-collapse>
                <el-collapse-item name="1">
                    <template slot="title">
                        <el-button style="margin-right: 15px;" icon="el-icon-plus" size="mini" type='success'  @click='show_dialog()'>Client</el-button>
                        FILTRE
                    </template>

                    <div class="filtre">
                        <el-form @submit.prevent.native='refresh_info()'>
                            <el-row :gutter="20">
                                
                                <el-col :md='6' :xs='24'>
                                    <el-form-item label='Client' >
                                        <el-select 
                                        v-cancel-read-only
                                        id="TxtClient" 
                                        class='full-width' 
                                        @change="change_client()" 
                                        v-model='Filters.IdClient' 
                                        filterable 
                                        :clearable="true" 
                                        @clear="select_toti_clientii()"
                                        >
                                            <el-option label='Toti' value='-1'/>
                                            <el-option v-for='item in Info.Clienti' :key="'clienti' + item.Id" :value='item.Id' :label="item.Nume" >
                                                <span style="float: left">{{ item.Nume }}</span>
                                                <span v-if="item.Sters == '1'" style="float: right; color: red; font-size: 13px"> 
                                                    <el-tag type="danger" size="mini">STERS</el-tag>
                                                </span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='4' :xs='24'>
                                    <el-form-item label='Observatii' >
                                        <el-input v-model='Filters.Observatii' placeholder='Observatii...' />
                                    </el-form-item>
                                </el-col>     

                                <el-col :md='4' :xs='24'>
                                    <el-form-item label="Tip" >
                                        <el-select v-model="Filters.Stersi" @change='refresh_info()' class='full-width' style='margin-bottom:10px'>
                                            <el-option value="0" label="Activi"></el-option>
                                            <el-option value="1" label="Stersi"></el-option>
                                            <el-option value="-1" label="Toti"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>                        

                                <el-col :md='3' :xs='12' class='filter-buttons' >
                                    <el-form-item :label="PaginationInfo.RowCount + ' items'" >
                                        <el-button size="mini" type='primary' native-type='submit' @click='refresh_info()'> Cauta </el-button>
                                    </el-form-item>
                                </el-col>
                                


                            </el-row>
                        </el-form>
                        </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>

    

        <el-table :data="Results"  @sort-change='on_sort_change'  >
            <el-table-column sortable="custom" prop='Id' label='Id' min-width='100'></el-table-column>

            <el-table-column sortable prop='Nume' label='Nume' min-width='200'>
                <template slot-scope="scope">
                    {{scope.row.Nume}} 
                    <div v-if="scope.row.Sters == '1'">
                        <el-tag type="danger" size="mini">STERS</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop='Observatii' label='Observatii' min-width='200'></el-table-column>
            
            <el-table-column prop='DataCreare' label='Data creare' min-width='200'></el-table-column>
            <el-table-column prop='UserCreare' label='User creare' min-width='200'></el-table-column>

            <el-table-column fixed="right" align='center' label="Actiuni"  min-width='150'  v-if="!is_mobile()">
                <template slot-scope="scope" >
                    <el-tooltip content="Alege client default">
                        <el-button type="warning" size='small' icon="el-icon-right"  circle @click="alege_client(scope.row.Id, scope.row.Nume)" />
                    </el-tooltip>
                    <el-button type="primary" icon="el-icon-edit" size='mini'    circle @click="show_dialog(scope.row.Id)" />
                    <el-button type="danger"  v-if="$has_right('stergere_client') && scope.row.Sters=='0'" icon="el-icon-delete"  size='mini' circle @click="delete_item(scope.row)" />
                    <el-button type="warning" v-if="scope.row.Sters=='1'" icon="el-icon-upload2"  size='mini' circle @click="recover_item(scope.row)" />
                </template>
            </el-table-column>


            <el-table-column fixed="right" label="Act." width="50" v-if="is_mobile()">
                <template slot-scope="scope" >
                    
                    <el-popover placement="bottom" width="80" trigger="click" >
                        <div>
                            <el-tooltip content="Alege client default">
                                <el-button type="warning" size='small' icon="el-icon-right"  circle @click="alege_client(scope.row.Id, scope.row.Nume)" />
                            </el-tooltip>
                            <el-button type="primary" icon="el-icon-edit" size='mini'    circle @click="show_dialog(scope.row.Id)" />
                            <el-button type="danger"  v-if="$has_right('stergere_client') && scope.row.Sters=='0'" icon="el-icon-delete"  size='mini' circle @click="delete_item(scope.row)" />
                            <el-button type="warning" v-if="scope.row.Sters=='1'" icon="el-icon-upload2"  size='mini' circle @click="recover_item(scope.row)" />
                        </div>
                        <el-button slot="reference" style="float:right; padding: 10px !important; margin-left: 5px" icon="el-icon-arrow-down"  size='mini'/>
                    </el-popover>

                </template>
            </el-table-column>
        </el-table>
    
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Clienti-dialog ref='dlg' @save="on_back_from_dialog" />

    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Clienti_dialog from '@/pages/clienti/Clienti_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import eventBus from '@/backend/EventBus';


export default {
    name: "clienti",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Clienti-dialog': Clienti_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            search_clienti_result:[],
            base_url: '',
            Info: { Clienti: [] },
            Filters: { IdClient: '-1' , Observatii: '', Stersi: "0" },
            OrderBy: { Id: "DESC" },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
   
    methods: {
        
        async on_back_from_dialog(info){
            
            if( info.mode == "add" ){
                await this.get_info();
                this.alege_client( info.new_id, info.new_name);
            } else {
                await this.refresh_info();
            }
        },
        async get_info() {
            var response            = await this.post("clienti/get_info" );
            this.Info.Clienti       = response.Clienti;
            this.Filters.IdClient   = '-1';
            var id_client_selectat  = settings.get_id_client_selectat();

            if( id_client_selectat != null && id_client_selectat != '' ) {
                this.Filters.IdClient = id_client_selectat;
            }

            this.refresh_info();
        },


        set_search_text(value){
            this.Filters.Nume = value;
            this.refresh_info();
        },

        alege_client(id, nume){
            settings.set_id_client_selectat  (id);
            settings.set_nume_client_selectat(nume);

            eventBus.$emit('change-client-event');

            this.get_info();
        },

        async refresh_info() {
            var response        = await this.post("clienti/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('clienti');
        },

        async change_client() {
            this.Filters.Stersi = "-1";
            this.refresh_info();
        },

        async select_toti_clientii(){
            this.Filters.IdClient = "-1";
            this.refresh_info();
        },

        on_sort_change({ column, prop, order }){

            this.OrderBy    = {};
            var columnName  = prop;
            var orderColumn = 'ASC';

            if( order == 'descending'   ) orderColumn = 'DESC';
            if( order == null           ) orderColumn = '';

            this.OrderBy[columnName] = orderColumn;
            this.refresh_info();
        },

        async querySearchAsync(queryString, cb) {
            try{
                
                var results                 = await this.post("clienti/remote_search", { query: queryString } );
                this.search_clienti_result  = results.data;
                cb(this.search_clienti_result);
            }catch{
                console.log("error on fetching!");
            }
            
            
        },

        reset() {
            this.Filters = { Nume: '' , Telefon: '' };
            this.refresh_info();
        },

        async delete_item( item ) {
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("clienti/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        
        async recover_item( item ) {
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("clienti/recover_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url       = settings.BASE_URL;
        
        if( !this.$has_right("pagina_de_clienti") )  this.$router.push("/no-rights");

        this.get_info();

        eventBus.$on('clear-client-event', () => {
            this.get_info();
        });

        eventBus.$on('alege_client', () => {
            this.get_info();
        });
    }
};
</script>

<style lang="less" scoped>
   
    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
       
    }

</style>
